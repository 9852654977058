<template>
    <footer class="w-full flex-none bg-grind">
        <div class="container pb-12 pt-12 md:pb-0">
            <div class="mx-auto h-full gap-x-6 md:flex md:justify-between">
                <div
                    class="mb-12 flex flex-col gap-6 px-4 pt-4 md:w-1/4 md:flex-none md:px-0 md:pt-0"
                >
                    <NuxtLink to="/" class="block">
                        <img
                            alt="Roastar Logo"
                            class="h-8 fill-white lg:h-[50px]"
                            src="~/assets/images/roastar_logo_white.svg"
                        />
                    </NuxtLink>
                    <SocialLinks :show-borders="false" class="-ml-2 md:ml-0" />
                    <ContactLinks />
                </div>
                <ClientOnly>
                    <NewsletterSignup
                        class="mb-12 px-4 md:flex-1 md:px-0"
                        form-classes="mx-auto md:w-4/5 md:mx-0"
                        tag="footer"
                    />
                </ClientOnly>
                <FooterNav :menu="menu" />
            </div>
        </div>
        <div class="pb-6 text-center text-white">
            Copyright {{ currentYear() }} Roastar, Inc.
        </div>
    </footer>
</template>

<script setup lang="ts">
import SocialLinks from '~/components/social/SocialLinks.vue';
import NewsletterSignup from '~/components/social/NewsletterSignup.vue';

import ContactLinks from '~/components/social/ContactLinks.vue';
import FooterNav from '~/layouts/includes/FooterNav.vue';
import footerMenu from '~/data/footerMenu';
import { currentYear } from '~/utils/helpers';

const props = withDefaults(
    defineProps<{
        useStoryblok?: boolean;
    }>(),
    {
        useStoryblok: false,
    },
);

const menu = ref<Array<any>>([]);
if (props.useStoryblok) {
    const { data } = await useStoryblokApi().get('cdn/stories/config', {
        resolve_links: 'url',
    });
    const { data: storyblokMenu } = await useFetch(`/nuxt-api/global/footer`);
    menu.value = storyblokMenu.value;
} else {
    menu.value = footerMenu;
}
</script>
